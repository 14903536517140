import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'
import moment from 'moment'
import siteinfo from '@/util/global.js'
import router from '@/router'
class Adopt{

    /**
     * 获取认养分类信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getAdoptCategroy(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.key ) param.key = key
        common.getDataList("getAdoptCategoryList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 新增或编辑认养分类信息
     * @param {object} data 
     * @param {function} fn 
     * @returns 
     */
    addOrEditCategory(data,fn){
        if( !data.name ){
            tool.message("请输入分类名称","warning")
            return
        }
        if( !data.id ) delete data.id
        $post("setAdoptCategory",data).then(res=>{
            tool.message("保存成功")
            fn(res)
        })
    }

    /**
     * 获取认养列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getAdoptList( page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.shop_id ) param.shop_id = extra.shop_id
            if( extra.category_id ) param.category_id = extra.category_id
            if( extra.is_chil ) param.is_chil = extra.is_chil
        }
        let api = extra.shop_id ?'getShopAdoptList' :'getAdoptionList'

        common.getDataList(api,param).then(res=>{
            res.list.forEach(item=>{
                item.favorable_num = parseFloat(item.favorable_num)
            })
            fn(res)
        })
    }

    /**
     * 添加、编辑认养信息
     * @param {object} adopt 基本信息
     * @param {object} adoptDelivery 配送周期
     * @param {function} fn 
     */
     addOrEditAdopt(adopt,adoptDelivery,fn){
        if( adopt.delivery_type == 2 ){
            if( adoptDelivery.length  == 0 ){
                tool.message("请添加配送周期信息","warning")
                return
            }
            const checkDataIsNull = (data)=> {return ( data == "" || data<=0 ) ? true :false}

            let flag = false
            for (var i = 0; i < adoptDelivery.length; i++) {
                let { day,min_times,alias }  = adoptDelivery[i]
                if( checkDataIsNull(day) || checkDataIsNull(min_times) || checkDataIsNull(alias) ){
                    flag = true
                    break
                }
            }
            if( flag ) {
                tool.message("请将配送周期信息填写完整后再提交","warning")
                return
            }
        }

        if( adopt.contract_status == 1 && !adopt.contract_id ){
            tool.message("请选择合同","warning")
            return
        }

        if( !adopt.id ) delete adopt.id
        if( adopt.slide.length > 0 ) adopt.cover = adopt.slide[0]
        if( adopt.live_ids.length == 0 ) adopt.live_ids = null
        $post("createOrUpdateAdopt",{adopt,adoptDelivery}).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 获取认养详情信息
     * @param {number} id 
     * @param {function} fn 
     */
    getAdoptDetail(id,extra,fn){
        let param = {id}
        if( extra && extra.shop_id ) param.shop_id = extra.shop_id
        $post('getAdoptDetail',param).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 获取统计信息
     * @param {function} fn 
     */
    getAdoptStatistics(fn){
        $post("getAdoptTjStatistics").then(res=>{
            fn(res.data)
        })
    }
    
    /**
     * 获取认养项目特点
     * @param {number} adopt_id 
     * @param {function} fn 
     */
    getAdoptFeatures(adopt_id,fn){
        $post("getAdoptProjectFeatures",{adopt_id}).then(res=>{
            fn(res.data ? res.data.content :[])
        })
    }

    /**
     * 保存认养特点
     * @param {array} param 
     * @param {number} adopt_id 
     * @param {function} fn 
     * @returns 
     */
    saveAdoptFeature(param,adopt_id,fn){
        // if( param.length == 0 ){
        //     tool.message("请添加项目特点信息",'warning');
        //     return
        // }
        let flag = false
        for (var i = 0; i < param.length; i++) {
            if( param[i].img == "" || param[i].name=="" || param.summary == '' ){
                flag = true
                break
            }
        }
        if( flag ){
            tool.message("请将项目特点内容填写完整",'warning');
            return
        }
        let data = {
            adopt_id,
            content:param
        }
        $post("AdoptProjectFeatures",data ).then(res=>{
            tool.message("保存成功");
            fn(res.data)
        })
    }

    /**
     * 获取认养流程详情
     * @param {number} adopt_id 
     * @param {function} fn 
     */
    getFlowDetail(adopt_id,fn){
        $post("getAdoptionFlow",{adopt_id}).then(res=>{
            let d = res.data
            let data = {
                status:d.status ? d.status :2,
                ranking_status:d.ranking_status ? d.ranking_status :2,
                ranking_num:d.ranking_num || "",
                ranking_info:d.ranking_info || "",
                qa_status:d.qa_status ? d.qa_status :2,
                qa_array:d.qa_array ? d.qa_array :[],
                flowinfo:d.flowinfo ? d.flowinfo :[],
            }
            fn(data)
        })
    }

    /**
     * 保存认养流程信息
     * @param {object} param 
     * @param {number} adopt_id 
     * @param {function} fn 
     * @returns 
     */
    saveAdoptFlow(param,adopt_id,fn){
        if( param.length == 0 ){
            tool.message("请添加项目流程信息","warning");
            return
        }

        let flag = false , flowinfo = param.flowinfo
        for (var i = 0; i < flowinfo.length; i++) {
            if( flowinfo[i].img == "" || flowinfo[i].name=="" || flowinfo.summary == '' ){
                flag = true
                break
            }
            if( param.flowinfo[i].time && flowinfo[i].time.length == 2 ){
                param.flowinfo[i].time[0] = moment(flowinfo[i].time[0]).format("YYYY-MM-DD")
                param.flowinfo[i].time[1] = moment(flowinfo[i].time[1]).format("YYYY-MM-DD")
            }
        }
        if( flag ){
            tool.message("请将项目流程内容填写完整","warning");
            return
        }
        param.adopt_id = adopt_id
        $post("AdoptionFlow",param ).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        },()=>{})
    }

    /**
     * 保存认养问答信息
     * @param {object} param 
     * @param {number} adopt_id 
     * @param {function} fn 
     * @returns 
     */
    saveAdoptIssue(param,adopt_id,fn){
        if( param.qa_array.length == 0 ){
            tool.message("请添加问答信息","warning");
            return
        }
        let flag = false
        for (var i = 0; i < param.qa_array.length; i++) {
            if( param.qa_array[i].ask_title == "" || param.qa_array[i].asnwer=="" ){
                flag = true
                break
            }
        }
        if( flag ){
            tool.message("请将问答内容填写完整","warning");
            return
        }
        param.adopt_id = adopt_id
        $post( "AdoptionFlow",param ).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 保存认养排名
     * @param {object} param 
     * @param {number} adopt_id 
     * @param {function} fn 
     * @returns 
     */
    saveAdoptSort(param,adopt_id,fn){
        if( param.ranking_status == 1) {
            if( param.ranking_num <=0 ){
                tool.message('排名个数必须大于0',"warning")
                return
            }
        }
        
        param.adopt_id = adopt_id
        $post( "AdoptionFlow",param ).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 获取认养环境信息
     * @param {number} adopt_id 
     * @param {function} fn 
     */
    getAdoptArea(adopt_id,fn){
        $post("getAdoptArea",{adopt_id}).then(res=>{
            let d = res.data
            let data = {
                status: d ? d.status : 2,
                name:d ? d.name : '',
                user_name:d ? d.user_name : '',
                user_avatar:d ? d.user_avatar : '',
                area: d ? d.area :{
                    area:"",
                    point:[],
                    address:"",
                    lat:"",
                    lng:""
                }
            }
            fn(data)
        })
    }

    /**
     * 保存环境信息
     * @param {object} param 
     * @param {number} adopt_id 
     * @param {function} fn 
     */
    saveAdoptEnvironment(param,adopt_id,fn){
        param.adopt_id = adopt_id
        $post( "AdoptArea",param ).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 复制一条认养数据
     * @param {number} adopt_id 
     * @param {function} fn 
     */
    copyAdopt(adopt_id,fn){
        tool.confirm("确认添加一条周期信息吗？").then(()=>{
            $post("copyAdoption",{id:adopt_id}).then(res=>{
                tool.message("复制成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取认养周期信息
     * @param {number} id 
     * @param {function} fn 
     */
    getAdoptCycle(id,fn){
        $post("getAdoptcycles",{adopt_id:id}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 设置当前认养周期状态
     * @param {number} adopt_id 
     * @param {number} status 
     * @param {function} fn 
     */
    changeAdoptCycleStatus(adopt_id,status,fn){
        tool.confirm("确认切换当前周期状态吗？").then(()=>{
            let param = { adopt_id,status:adopt_status}
            $post("updateAdoptStatuss",param).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 切换认养上下架状态
     * @param {number} id 
     * @param {number} status 
     * @param {function} fn 
     */
    changeAdoptPutAway(id,status,fn){
        tool.confirm("确认切换当前周期状态吗？").then(()=>{
            let param = { pk:id,type:35,value:status}
            $post("changeSomething",param).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 删除认养
     * @param {number} id 
     * @param {function} fn 
     */
    deleteAdopt(id,fn){
        tool.confirm("确认删除认养信息吗？").then(()=>{
            $post("deleteAdoption",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取认养工单服务信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
     getAdoptWorkServer(page,limit,extra,fn){
        let param = {page,limit}
        if( extra.shop_id ) param.shop_id = extra.shop_id
        common.getDataList("AdoptWorkOrderServerList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 删除认养工单服务
     * @param {number} id 
     * @param {function} fn 
     */
    deleteAdoptWorkServer(id,fn){
        tool.confirm("确认删除管理记录信息吗？").then(()=>{
            $post("deleteAdoptWorkOrderServer",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取认养工单管理项
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getAdoptWorkOrder(page,limit,fn){
        let param = {page,limit}
        common.getDataList("AdoptworkOrderList",param).then(res=> fn(res))
    }

    /**
     * 获取认养订单列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getAdoptOrder(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.key ){
                if(extra.selectType ==1 ) param.order_number = extra.key
                if(extra.selectType ==2 ) param.name = extra.key
                if(extra.selectType ==3 ) param.mobile = extra.key
            }
            if( extra.searchTime && extra.searchTime.length >0 ){
                param.start_time = moment(extra.searchTime[0]).format("YYYY-MM-DD")
                param.end_time = moment(extra.searchTime[1]).format("YYYY-MM-DD")
            }
            if( extra.order_status || extra.order_status == 0){
                let status_arr = ['0','1','2','3','4']
                if( status_arr.includes(extra.order_status) ) param.order_status = extra.order_status
                if( extra.order_status == 5 ) param.is_refund = 1
                if( extra.order_status == 6 ) param.is_cancel = 1
                if( extra.order_status == 7 ) param.is_recycle = 1
            }
        }
        common.getDataList("getAdoptionOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 认养订单操作
     * @param {number} id   认养订单id
     * @param {number} type 操作类型 2移入回收站 6 删除订单 cancel取消订单 3确认收货
     * @param {string} title 操作提示
     * @param {function} fn 
     */
    handleAdoptOrder(id,type,title,fn){
        tool.confirm(title).then(()=>{
            //取消订单
            if( type == 'cancel' ){
                let param = { pk:id,order_type:3 }
                $post("cancelOrder",param).then(res=>{
                    tool.message("操作成功")
                    fn(res.data)
                })
                return
            }
            //移入回收站 删除
            if( type == 2 || type == 6 || type == 3){
                let param = { pk:id ,type,order_type:10 }

                if( type == 3 ) param.order_type = 3  //确认收货
                $post("doSomethingByOrder",param).then(res=>{
                    tool.message("操作成功")
                    fn(res.data)
                })
                return
            }
            
            //批量收货
            if( type == 'batch-confirm'){
                if( !id || id.length <= 0 ){
                    tool.message("请选择订单信息")
                    return
                }
                $post("AdoptConfirm",{ids:id}).then(res=>{
                    tool.message("操作成功")
                    fn(res.data)
				})
            }
        }).catch(()=>{})
    }

    /**
     * 认养订单发货操作
     * @param {object}} param 
     * @param {function} callback 
     * @returns 
     */
    deliveryByExpress(param,callback){
        if( !param.name || !param.phone || !param.address ){
            tool.message('收货信息不完整，不能进行发货！',"warning")
            return
        }
        if( !tool.returnMessage(param.express_company,"请选择快递公司")) return
        if( !tool.returnMessage(param.express_no,"请填写快递单号")) return
        
        $post("doSomethingByOrder",param).then(res=>{
            tool.message("发货成功")
            callback(res.data)
        })
    }

    /**
     * 导出认养订单
     * @param {array} ids 
     */
    exportAdoptOrder(ids){
        if( ids.length == 0 ){
            tool.message("请选择要导出的订单","warning")
            return
        }
        let url = siteinfo.siteinfo.siteroot+'/api/admin/adoptOrderExport?token='+localStorage.getItem('token')
        url+= '&ids='+JSON.stringify(ids)
        window.location.href = url
    }

    /**
     * 导出认养配送订单
     * @param {array} ids 
     * @returns 
     */
    exportAdoptSendOrder(ids){
        if( ids.length == 0 ){
            tool.message("请选择要导出的订单","warning")
            return
        }
        let url = siteinfo.siteinfo.siteroot+'/api/admin/exportSendOrderByAdopt?token='+localStorage.getItem('token');
        url+= '&ids='+ids.join(',')
        window.open(url);
    }

    /**
     * 认养订单批量发货
     * @param {string} url 
     */
    adoptOrderBatchSend(url){
        $post('adoptOrderShip',{file_url:url}).then(res=>{
            tool.message('批量发货成功')
        }).catch(()=>{})
    }
    /**
     * 批量发货
     * @param {string} url 
     */
    adoptDeliveryOrderBatch(url){
        $post('AdoptBulkDelivery',{file_url:url}).then(res=>{
            tool.message('批量发货成功')
        }).catch(()=>{})
    }
   
    /**
     * 获取认养订单详情
     * @param {number} id 
     * @param {function} fn 
     */
    getAdoptOrderDetail(id,fn){
        $post('getAdoptOrderDetail',{pk:id}).then(res=>{
            fn(res.data)
        },()=>{})
    }

    /**
     * 认养订单退款操作
     * @param {number} price 
     * @param {number} order_id 
     * @param {function} fn 
     * @returns 
     */
    adoptOrderRefund(price,order_id,fn){
        if( !price || price <=0 ){
            tool.message("请填写退款金额，且退款金额必须大于0","warning")
            return
        }
        tool.confirm("确认要对该订单进行退款操作吗?").then(()=>{
            let param = {price,pk:order_id}
            $post("agreeAdoptRefund",param).then(res=>{
                tool.message("退款成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 设置认养订单信息
     * @param {object} data 要修改的数据
     * @param {string} title 提示语
     * @param {function} fn 
     * @returns 
     */
    setAdoptOrderInfo(data,title,fn){
        let param = {...data}
        if( param.ripe_time ) param.ripe_time = moment(param.ripe_time).format("YYYY-MM-DD")
        if( title ){
            tool.confirm(title).then(()=>{
                $post("changeAdoptOrderDetail",param).then(res=>{
                    tool.message("操作成功")
                    fn(res.data)
                })
            }).catch(()=>{})
            return
        }
        $post("changeAdoptOrderDetail",param).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        })
    }

    /**
     * 获取认养状态信息跟踪
     * @param {number} id 
     * @param {function} fn 
     */
    getAdoptStatus(id,fn){
        let param = {detail_id:id}
        $post("getAdoptStatusList",param).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 获取认养服务信息
     * @param {number} adopt_id 
     * @param {function} fn 
     */
    getAdoptServe(adopt_id,fn){
        $post("getAdoptServers",{adopt_id}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 添加或编辑认养状态跟踪信息
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditAdoptStatus(param,fn){
        $post("addAdoptSourceLog",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 添加认养周期
     * @param {object} param 
     * @param {function} fn 
     * @returns 
     */
    createAdoptCycle(param,fn){
        if( !tool.returnMessage(param.name,'请填写收件人姓名')) return
        if( !tool.returnMessage(param.phone,'请填写收件人联系电话')) return
        if( !tool.returnMessage(param.address,'请填写收货地址')) return
        param.order_type = 3
        $post("createTimeCycleByAdopt",param).then(res=>{
            tool.message("添加成功")
            fn(res.data)
        })
    }

    /**
     * 获取认养配送订单
     * @param {number} page 
     * @param {object} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getAdoptDeliveryOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra  && extra.delivery_status >= 0){
            param.delivery_status = extra.delivery_status ?? ""
        }
        common.getDataList("adoptDeliveryList",param).then(res=>fn(res))
    }

    /**
     * 获取认养操作类型详情
     * @param {number} id 
     * @param {function} fn 
     */
    getAdoptWorkDetail(id,fn){
        $post('AdoptWorkOrderDetails',{id}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 删除认养操作类型项
     * @param {number} id 
     * @param {function} fn 
     */
    deleteAdoptWorkItem(id,fn){
        tool.confirm("确认删除操作项信息吗？").then(()=>{
            $post("deleteAdoptWorkOrderServerDetails",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 新增或编辑认养操作类型
     * @param {object} param 
     */
    addOrEditAdoptWork(param){
        if( !tool.returnMessage(param.title,'请填写操作类型名称')) return
        if( !param.id) delete param.id
        $post("AdoptWorkOrderServerAddOrEdit",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取合同列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getAdoptContract(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
        }
        common.getDataList("getContractList",param).then(res=>{
            res.list.forEach(item=>item.owner = JSON.parse(item.owner))
            fn(res)
        })
    }

    /**
     * 新增或编辑合同
     * @param {object} param 
     */
    addOrEditContract(param){
        if( !tool.returnMessage(param.name,"请输入合同名称")) return
        if( !tool.returnMessage(param.common_seal,'请上传公章')) return
        if( !tool.returnMessage(param.party_b,'请输入乙方') ) return
        if( !tool.returnMessage(param.agreement,'请输入合同内容')) return
        if( !param.id ) delete param.id
        $post("createOrUpdateContract",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取签订的合同信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getContractUser(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.contract_id ) param.contract_id = extra.contract_id
        }
        common.getDataList("getContractUserList",param).then(res=>fn(res))
    }

    /**
     * 获取合同详情
     * @param {number} id 
     * @param {array} keys 
     * @param {function} fn 
     */
    getContractDetail(id,keys=null,fn){
        $post("getContractIndex",{id}).then(res=>{
            let data = res.data
            if( data.owner && typeof data.owner == 'string'){
                data.owner = JSON.parse(data.owner)
            }
            if( !keys ){
                fn(data)
                return
            }
            fn(tool.filterForm(keys,data))
        })
    }

    /**
     * 查看下载合同
     * @param {number} id 
     * @param {function} fn 
     */
    getContractOrder(id){
        $post("getContract",{id}).then(res=>{
            window.open(res.data.url)
            // var alink = document.createElement("a");
            // alink.href = res.data.url
            // alink.download = "contract"; //图片名
            // alink.click();
        }).catch(()=>{})
    }
}
const adoptModel = new Adopt()

export default adoptModel